import React from "react";
import {
	AnnotationIcon,
	GlobeIcon,
	LightningBoltIcon,
	ScaleIcon,
	VideoCameraIcon,
	UserGroupIcon,
	InformationCircleIcon,
	LightBulbIcon,
} from "@heroicons/react/outline";

export default function Specialties() {
	const features = [
		{
			name: "Role Model",
			description:
				"Mentors who are pioneers in their fields including Korean culture, art, and traditions will serve as a compass for your life.",
			icon: UserGroupIcon,
		},
		{
			name: "Practical Advice",
			description:
				"We help you to reduce the trial and error experienced by the mentors with practical advice rather than a simple theory.",
			icon: LightBulbIcon,
		},
		{
			name: "Diverse Cultural Backgrounds",
			description:
				"Mentors from different countries will help you gain broader perspectives to different topics available at The Director.",
			icon: GlobeIcon,
		},
		// {
		// 	name: "The Director Content Itself",
		// 	description:
		// 		"The Secret of K-POP / Professionals in South Korea / Global Creators / Traditional Korean Culture and the Arts / Authentic Lessons / Nurture Life and Diversity",
		// 	icon: VideoCameraIcon,
		// },
	];
	return (
		<div className="py-12 bg-black">
			<div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
				<div className="lg:text-center">
					<h2 className="text-base text-primary font-semibold tracking-wide uppercase">
						Specialties
					</h2>
					<p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl font-hn-ex">
						What we are best at
					</p>
				</div>

				<div className="mt-12">
					{/* <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
						{features.map((feature) => (
							<div key={feature.name} className="relative flex items-center justify-center flex-col text-center">
							
									<div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-primary-900">
										<feature.icon className="h-6 w-6" aria-hidden="true" />
									</div>
									
								
								<p className=" text-lg leading-6 mt-5 font-medium text-white">
										{feature.name}
									</p>
								<p className="mt-2  text-base text-gray-300">
									{feature.description}
								</p>
							</div>
						))}
					</dl> */}

					<div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
						{features.map((feature) => (
							<div key={feature.name} className="pt-6">
								<div className="flow-root rounded-lg bg-gray-900 px-6 pb-8 h-full">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center rounded-xl bg-primary p-3 shadow-lg">
												<feature.icon
													className="h-6 w-6 text-black"
													aria-hidden="true"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-lg font-medium tracking-tight text-white">
											{feature.name}
										</h3>
										<p className="mt-5 text-base text-gray-500">
											{feature.description}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
