import { StarIcon } from "@heroicons/react/solid";
import React from "react";
import { classNames } from "../../helpers/common";

export default function Stars({ rate }) {
	return (
		<div className="flex items-center">
			{[0, 1, 2, 3, 4].map((rating) => (
				<StarIcon
					key={rating}
					className={classNames(
						rate > rating ? "text-primary-500" : "text-gray-300",
						"flex-shrink-0 h-5 w-5"
					)}
					aria-hidden="true"
				/>
			))}
		</div>
	);
}
