import React from "react";
import { ArrowRightIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import { classNames } from "../../helpers/common";
import { useFetch } from "../../services/classes";
import Container from "../layout/Container";

const projects = [
	{
		name: "Graph API",
		initials: "GA",
		href: "#",
		members: 16,
		bgColor: "bg-pink-600",
	},
	{
		name: "Component Design",
		initials: "CD",
		href: "#",
		members: 12,
		bgColor: "bg-purple-600",
	},
	{
		name: "Templates",
		initials: "T",
		href: "#",
		members: 16,
		bgColor: "bg-yellow-500",
	},
	{
		name: "React Components",
		initials: "RC",
		href: "#",
		members: 8,
		bgColor: "bg-green-500",
	},
];

export default function CategoriesCards() {
	const { data: categories, loading, error } = useFetch(`categories`);

	return (
		<div className="py-24 pt-20">
			<Container>
				<div className="lg:text-center mb-10">
					<h2 className="text-base text-primary font-semibold tracking-wide uppercase">
						Explore
					</h2>
					<p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl font-hn-ex">
						Our library of classes
					</p>
				</div>
				<ul
					role="list"
					className="mt-3 grid grid-cols-1 gap-8 sm:gap-8 sm:grid-cols-2 lg:grid-cols-3"
				>
					{categories &&
						categories.map((category) => (
							<li
								key={category.name}
								className="col-span-1 flex shadow-sm rounded-[0.7rem]"
							>
								{/* <div
									className={classNames(
										category.bgColor,
										"bg-gray-800",
										"flex-shrink-0 flex items-center justify-center w-16 text-gray-400 text-sm font-medium rounded-l-md"
									)}
								>
									{category.classesCount}
								</div> */}
								<div className="flex-1 flex items-center justify-between bg-gray-900 rounded-r-[0.7rem] rounded-l-[0.7rem] truncate">
									<a
										className="flex-1 flex items-center px-4 pl-8 py-4 text-sm  text-gray-100 font-medium hover:text-white"
										href={`/explore/${category.name}`}
									>
										<span className="flex-1 ">{category.name}</span>
										<span className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 self-end ">
											<ArrowRightIcon className="w-5 h-5" aria-hidden="true" />
										</span>
									</a>
								</div>
							</li>
						))}
				</ul>
			</Container>
		</div>
	);
}
