import React, { useState } from "react";
import mainImage from "../../images/hero.gif";
import LogoImage from "../../images/dlogo.svg";
import AppStoreIcon from "../../images/appstore.png";
import PlayStoreIcon from "../../images/playstore.png";
import Notice from "../shared/Notice";
import Button from "../ui/Button";
import Container from "../layout/Container";
import { useAuth } from "../../context/AuthContext";

export default function Banner() {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(null);

	const { authModal } = useAuth();

	function subscribe(e) {
		e.preventDefault();

		const email = e.target["email"].value;
		if (!email) return;
		setLoading(true);
		const options = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: email,
				resubscribe: false,
				autoresponders: true,
				country: "Lebanon",
				type: "null",
			}),
		};

		fetch("#", options)
			.then((response) => {
				if (response.status === 200) {
					setSuccess(true);
				} else {
					setSuccess(false);
				}
				response.json();
				setLoading(false);
			})
			.catch((err) => {
				//setSuccess(false);
				setLoading(false);
			});
	}

	return (
		<Container>
			<div className="bg-black py-12 !pt-[8rem] md:pt-[11rem] md:pb-[8rem]">
				<div className="relative overflow-hidden">
					<div className="relative lg:max-w-7xl lg:grid lg:grid-cols-2 gap-x-4 items-center">
						<div className="pr-0 md:pr-16">
							<h1 className="tracking-tight font-extrabold text-neutral-900 sm:text-4xl md:text-[44px] text-2xl !leading-[1.1] ">
								<span className="block text-white font-hn-ex font-bold">
									Become the director of your own dreams
								</span>
							</h1>
							<p className="mt-6 max-w-md text-base text-neutral-400 sm:text-lg md:mt-6 md:text-xl md:max-w-3xl">
								Bringing together the sharpest minds and <br />
								creative artists in Korean culture.
							</p>

							<Button
								label="Get Started - Free"
								size="large"
								className="mt-12"
								onClick={() => authModal("join")}
							/>

							<p className="mt-8 max-w-md text-white md:max-w-md text-xs lg:text-[15px] leading-normal">
								Free unlimited access to all of our content.
								<br />
								No hidden fees.
							</p>
						</div>
						<div className="pt-12 md:pt-0">
							<img
								className="relative  shadow-lg"
								src={mainImage}
								alt="App screenshot"
							/>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
}

// TODO: add join link
