import React, { useState } from "react";
import mainImage from "../../images/app-iphones.jpg";
import LogoImage from "../../images/dlogo.svg";
import AppStoreIcon from "../../images/appstore.png";
import PlayStoreIcon from "../../images/playstore.png";
import Notice from "../shared/Notice";
import Button from "../ui/Button";
import Container from "../layout/Container";
import AndroidButton from "../ui/AndroidButton";
import AppleButton from "../ui/AppleButton";

export default function DownloadSection() {

	return (
		<Container>
		<div className="bg-black py-12 pb-0 md:pt-[4rem] md:pb-[0rem]">
			<div className="relative overflow-hidden">
			<div className="relative lg:max-w-7xl lg:flex lg:flex-row items-center">

				<div className="basis-1/2">
					<h1 className="tracking-tight font-extrabold text-neutral-900 sm:text-4xl md:text-[44px] text-2xl !leading-[1.1] ">
						<span className="block text-white font-hn-ex font-bold">
						Stream anywhere, anytime.
						</span>
					</h1>
					<p className="mt-6 max-w-md text-base text-neutral-400 sm:text-lg md:mt-6 md:text-xl md:max-w-3xl">
						Available on iOS and Android and Web
					</p>

					<div className="block md:flex mt-11">
						<a href="https://apps.apple.com/xk/app/the-director-k-classes/id1605643132" target="_blank" className="mr-6">
							<AppleButton />
						</a>
						<a href="https://play.google.com/store/apps/details?id=com.mevox.thedirector" target="_blank">
							<AndroidButton />
						</a>
					</div>

				</div>
				<div className="basis-1/2">
					<img
						className="relative  shadow-lg"
						src={mainImage}
						alt="App screenshot"
					/>
				</div>
			</div>

			</div>
		</div>
		</Container>
	);
}

