import React, { useEffect } from "react"
import ClassesSlider from "../components/classes/ClassesSlider"
import JoinCTA from "../components/cta/JoinCTA"
import JoinCTA2 from "../components/cta/JoinCTA2"
import Banner from "../components/home/banner"
import CategoriesCards from "../components/home/CategoriesCards"
import DownloadSection from "../components/home/downloadSection"
import FeaturedClass from "../components/home/featuredClass"
import Specialties from "../components/home/Specialties"
import Testimonials from "../components/home/Testimonials"
import Container from "../components/layout/Container"

import Layout from "../components/shared/layout"
import Seo from "../components/shared/seo"
import BottomBanner from "../components/ui/BottomBanner"
import Button from "../components/ui/Button"
import { useAuth } from "../context/AuthContext"
import { getPageData } from "../services/classes"

const IndexPage = () => {
  const {token} = useAuth();
	const [home, setHome] = React.useState(null);
	const [activeClasses, setActiveClasses] = React.useState(null);

	const renderSection = (section, key) => {
		switch(section["__component"]) {
			case "ui.classes":
				return <ClassesSlider 
					key={key} 
					index={key}
					classes={section.classes} 
					style={{marginBottom: 10}} 
					sectionTitle={section.title} 
					//openClass={setClassOpen}
				/>;
			case "ui.featured-class":
				return <FeaturedClass class={section.class} key={key} customCover={section.cover?.url} sectionTitle={section.title}  />
			// case "ui.active-classes":
			// 	return <ClassesSlider 
			// 		classes={activeClasses} 
			// 		key={key}
			// 		style={{marginBottom: 10}} 
			// 		sectionTitle={section.title} 
			// 		//openClass={setClassOpen}
			// 		showMore={false}
			// 		landscape={true}
			// 		playButton
			// 		bottomTitle
			// 	/>
		}
	}


	useEffect(() => {
		getPageData('home-web').then(res => setHome(res))
	}, [])

	// useEffect(() => {
	// 	if(token) {
	// 		getActiveClasses();
	// 	}
	// 	else {
	// 		setActiveClasses(null)
	// 	}
	// }, [token])


  return (
    <Layout transparentNavbar={false}>
		<Seo title="Home" />
		{!token && <Banner />}
		<div className="max-w-6xl mx-auto px-4 pr-0 sm:px-6 lg:px-8">
			{home && home[0]?.sections.map((section, index) => renderSection(section, index))}
			<div className="text-center mt-10">
				<Button
					label="View All Classes" 
					size="large" 
					color="secondary" 
					style={{marginTop: 20}} 
					link="/explore"
					uppercase
				/>
			</div>
		</div>
		<DownloadSection />
		<Testimonials />
		<Specialties />
		<CategoriesCards />
		{!token && <BottomBanner />}
		{!token && <JoinCTA2 />}
    </Layout>
  )
}

export default IndexPage
