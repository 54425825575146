import React from "react";
import Container from "../layout/Container";
import Stars from "../ui/Stars";
import Slider from "react-slick";
import "../../styles/slick-arrows.css";
import "../../styles/classes-slider.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useFetch } from "../../services/classes";

export default function Testimonials() {
	const { data: testimonials, loading, error } = useFetch(`testimonials`);

	const settings = {
		dots: true,
		arrows: false,
		autoplay: true,
  		autoplaySpeed: 3000,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		swipe: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className="bg-black pt-0 md:pt-16 lg:py-24 lg:pb-28 testimonials">
			<Slider {...settings}>
				{testimonials &&
					testimonials.map((item, index) => (
						<Testimonial key={index} data={item} />
					))}
			</Slider>
		</div>
	);
}

const Testimonial = ({ data }) => {
	return (
		<div className="pb-16 pt-16 md:pt-0 bg-gray-900 lg:pb-0 lg:z-10 lg:relative">
			<div className="lg:mx-auto lg:max-w-6xl ">
				<div className="lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
					<div className="relative lg:-my-8">
						{/* <div
							aria-hidden="true"
							className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"
						/> */}
						<div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full hidden md:block">
							<div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
								<img
									className="object-cover lg:h-full lg:w-full"
									src={data?.image?.url}
									alt=""
								/>
							</div>
						</div>
					</div>
					<div className="mt-0 md:mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
						<div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
							<blockquote>
								<div>
									{/* <svg
										className="h-12 w-12 text-white opacity-25"
										fill="currentColor"
										viewBox="0 0 32 32"
										aria-hidden="true"
									>
										<path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
									</svg> */}
									<Stars rate={data?.rate} />
									<p className="mt-6 text-base md:text-2xl font-medium text-white">
										{data?.review}
									</p>
								</div>
								<footer className="mt-6">
									<p className="text-base font-medium text-white">
										{data?.name}
									</p>
								</footer>
							</blockquote>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

function NextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={`${className} classes-slider-arrow right`}
			onClick={onClick}
		>
			<ChevronRightIcon />
		</div>
	);
}
function PrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={`${className} classes-slider-arrow left`} onClick={onClick}>
			<ChevronLeftIcon />
		</div>
	);
}
