import React, { useEffect, useState } from "react";
import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline";
import Button from "./Button";
import { useAuth } from "../../context/AuthContext";

export default function BottomBanner() {
	const [visible, setVisible] = useState(true);
	const {authModal} = useAuth();
	const handleScroll = () => {
		const position = window.pageYOffset;
		setVisible(position < 3555)
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		visible ? <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-10 hidden md:block">
			<div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 ">
				<div className="p-2 rounded-[0.7rem] bg-gray-800 bg-opacity-80 backdrop-blur-md shadow-lg sm:p-3">
					<div className="flex items-center justify-between flex-wrap">
						<div className="w-0 flex-1 flex items-center">
							<span className="flex p-2 rounded-lg bg-gray-700">
								<SpeakerphoneIcon
									className="h-6 w-6 text-white"
									aria-hidden="true"
								/>
							</span>
							<p className="ml-3 font-medium text-white truncate">
								<span className="md:hidden">
								Free unlimited access to all of our content.
								</span>
								<span className="hidden md:inline">
								Free unlimited access to all of our content.
								</span>
							</p>
						</div>
						<div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
							<Button label="Get Started" size="large" onClick={() => authModal("join")} />
						</div>
						{/* <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
							<button
								type="button"
								className="-mr-1 flex p-2 rounded-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white"
							>
								<span className="sr-only">Dismiss</span>
								<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
							</button>
						</div> */}
					</div>
				</div>
			</div>
		</div>
		: null
	);
}
