import React from "react";
import Container from "../layout/Container";
import Button from "../ui/Button";

export default function FeaturedClass(props) {
	const {class: classObj, customCover, sectionTitle} = props;

	return (
		<div className="hidden md:block pb-[8rem]">
			{/* <div className="text-center mb-8">
				<h1 className="tracking-tight text-3xl !leading-[1.1] block text-white font-hn font-bold">
					{sectionTitle}
				</h1>
			</div> */}
			<div className="bg-cover bg-center md:bg-right rounded-lg" style={{backgroundImage: `url(${customCover ? customCover : classObj?.cover.url})`}}>
				<div className="gradient-dark-right md:max-w-xs py-12 md:py-[11rem] ml-auto">
					<div className="relative z-0 text-center">
						{/* {nameFont ? <img src={nameFont?.url} style={{width: 400, maxWidth: '80%', margin: '0 auto'}}  /> : <div className="text-center text-5xl ">{name}</div>} */}
						<span className="inline-flex items-center px-3 py-1.5 rounded-full text-xs font-medium bg-white text-black mx-auto mb-6">
							Featured
						</span>
						<div className="text-center font-hn-ex font-bold text-[28px] uppercase max-w-[200px] leading-[1.1] mx-auto">{classObj?.name}</div>
						<div className="classBanner-objective text-center text-[16x] mt-6 leading-[1.4]">{classObj?.objective}</div>
						<div className="text-center mt-12 flex flex-row align-middle justify-center">
							{classObj?.trailer &&
								<Button
									label="Preview Class" 
									size="large" 
									color="white" 
									startIcon="PlayIcon"
									//TODO: onClick={() => play({ url: classObj?.trailer?.url, title: `Trailer: ${classObj?.name}`}, true)}
									uppercase
									link={`/class/${classObj?.id}`}
								/>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}